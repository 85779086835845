import './assets/fonts/Montserrat/Montserrat.css';
import '@bair/scripts/dist/shims';
import '@bair/styles/dist/components.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import Vue from 'vue';
import VueMq from 'vue-mq';
import SvgSprite from 'vue-svg-sprite';
import Components from '@bair/components';
import { Utils, breakpoints } from '@bair/scripts';
import sprite from '@bair/sprite';

import VeeValidate, { Validator } from 'vee-validate';

import './sass/base.scss';

const Main = () => import(/* webpackChunkName: 'views-main' */'./views/Main');

Vue.use(Components);
Vue.use(SvgSprite, { url: sprite });
Vue.use(VeeValidate);
Vue.use(VueMq, { breakpoints });

Validator.localize('en');
// Extend VeeValidate with non latin characters
VeeValidate.Validator.extend('supportedCharset', {
  // converted eastern europe chars to alphanum and space
  validate: (value) => !Utils.String.sanitize(value).match(/[^a-z ]/ig),
});

Vue.config.productionTip = false;
Vue.config.devtools = (process.env.NODE_ENV === 'development');
Vue.config.silent = !(process.env.NODE_ENV === 'development');

const app = new Vue({
  render: (h) => h(Main),
});

app.$mount('#app');
